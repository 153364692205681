.main {
  .content {
    .module-spp-detail {
      .options {
        .order_arrival_details_overlay {
          display: none;
        }
      }
    }
  }
}

.header-gnav-cart {
  .cart-confirm-wrapper {
    .subtotal {
      width: 385px;
      .right-copy {
        margin-#{$rdirection}: 25px;
      }
    }
  }
}

.elc-size-picker-wrapper,
.elc-product-shade-picker-dropdown {
  .elc-dropdown {
    &-readonly-input {
      width: 100%;
    }
    &-arrow {
      z-index: -1;
    }
  }
}

.mantle-media-asset {
  img,
  video {
    height: auto;
  }
}
