.address-form-popover {
  .fs {
    .form-item {
      width: 46%;
      height: auto;
      select {
        width: 190px;
      }
    }
    .default-bill-container {
      clear: left;
      width: auto;
    }
    .phone-container {
      width: 100%;
    }
  }
}

.default-address,
.defaultbill-address {
  display: block;
}

#foreground-node {
  max-height: 100%;
}

.registration-content {
  .first-column-wrap {
    fieldset.fs {
      .form_element {
        input {
          &.error {
            border: 1px solid $color-notice-pink !important;
          }
        }
      }
    }
  }
}

.past-purchases {
  .order {
    &.accordion-header {
      display: inline-block;
      .trans-id {
        width: 10%;
      }
      .order-status {
        width: 11%;
      }
      .view-details {
        width: 12%;
        float: #{$rdirection};
      }
    }
  }
}

.section-product {
  .overlay-container {
    iframe {
      min-height: 225px;
    }
  }
}

#sticky-bar {
  #sticky_bar_content {
    .sticky_content {
      .sticky_prod_select {
        padding-#{$rdirection}: 17%;
        .sbHolder {
          width: 150px;
        }
      }
    }
  }
}

.page-products {
  .mpp-view-options {
    @include swap_direction(margin, 0 0 10px 0);
    h2 {
      &.display_by {
        width: 270px;
      }
    }
    .mpp-sort-by {
      li {
        &:nth-child(2) {
          display: none;
        }
      }
    }
  }
}

.popup-offer-v1 {
  .subheading {
    @include swap_direction(margin, 0 0 15px 0);
  }
  .form-messages {
    @include swap_direction(margin, 0 0 0 20px);
  }
}

#customer_service {
  #footer {
    position: absolute;
    bottom: 13px;
    width: 100%;
  }
}

.module-spp-detail {
  .spp_product_status {
    .coming_soon_stock_msg,
    .temp_out_of_stock_msg {
      a.btn-email {
        @include swap_direction(margin, 5px 0);
        @include swap_direction(padding, 10px);
        display: block;
        width: 40%;
        float: none;
      }
    }
  }
  .shades {
    h3 {
      &.col1 {
        font-size: 14px;
      }
    }
  }
  .sizes {
    h3 {
      display: none;
    }
  }
}

.gnav-drop-down-v1 {
  .dd-content {
    &.active {
      width: auto;
      #store-locator {
        .form-text {
          @include swap_direction(padding, 0 0 0 3px);
          width: 248px;
        }
        .form-submit {
          @include swap_direction(padding, 0 10px);
          font-size: 12px;
          letter-spacing: 0;
          width: auto;
        }
      }
    }
  }
}

.section-store-locator {
  .store-locator-body {
    .search_container {
      .search {
        width: 225px;
        letter-spacing: 0;
      }
    }
    .search_alternative_text {
      @include swap_direction(margin, 3px 18px 0);
    }
  }
}

.shipping-billing {
  .section-column {
    &.last {
      display: none;
    }
    &.first {
      border: none;
    }
  }
}
